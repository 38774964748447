.cotainer-display {
	padding-top: 09.5rem;
}

.button-primary1 {
	color: #fff !important;
	background-color: #4d6985 !important;
	border-color: #4d6985 !important;
}

.errorTextbox {
	border-color: red !important;
}

.passwordErrorLabel {
	color: red;
	font-weight: bolder;
	text-align: left;
}

.emailErrorLabel {
	color: red;
	font-weight: bolder;
	text-align: left;
	margin-top: 8px;
}

.input-icons i {
	position: absolute;
}

.input-icons {
	width: 100%;
	color: black;
	text-align: right;
}

#eyeIcon {
	margin-left: -30px;
	padding-top: 5px;
}
