body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.avatar {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	font-size: 15px;
	font-weight: bold;
	font-family: roboto, arial, sans-serif;
	color: #092f56;
	line-height: 30px;
	text-align: center;
	background: #fff;
	display: inline-block;
	margin-right: 5px;
}

.btnprimary {
	letter-spacing: 0.5px !important;
	border: 1px solid #365576 !important;
	color: #fff !important;
	font-size: 14px !important;
	padding: 2px 7px 2px 5px !important;
	margin: 0px 5px 0 0 !important;
	background-color: #365576 !important;
	text-transform: unset !important;
	border-radius: 21px !important;
	width: 110px;
}

.btnprimary:hover {
	border: 1px solid #4e7094 !important;
	background-color: #4e7094 !important;
}

.autoComplete-list-review {
	color: #092f56;
	font-weight: 500;
}

.btnprimary-disable {
	letter-spacing: 0.5px !important;
	border: 1px solid #718daa !important;
	color: #fff !important;
	font-size: 14px !important;
	padding: 2px 7px 2px 5px !important;
	margin: 0px 5px 0 0 !important;
	background-color: #718daa !important;
	text-transform: unset !important;
	border-radius: 21px !important;
	width: 110px;
}

/* Scrollbar */

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

::-webkit-scrollbar-track {
	background-color: #eaeaea;
	border-left: 1px solid rgba(0, 0, 0, 0.2);
	border-right: 1px solid rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.7);
}

::-webkit-scrollbar-thumb:hover {
	background-color: rgba(0, 0, 0, 0.7);
}
