footer {
	color: #3f2edf;
	padding: 15px;
	width: 90%;
	bottom: 0;
	position: fixed;
	text-align: left;
}

footer.sticky-footer {
	padding: 1rem 0;
	flex-shrink: 0;
	background-color: whitesmoke;
}

footer.sticky-footer .copyright {
	line-height: 1;
	font-size: 0.8rem;
}

body.sidebar-toggled footer.sticky-footer {
	width: 100%;
}

.bg-color {
	background-color: #b0cdd8;
}

.topbar {
	height: 3.39rem;
}

.logo {
	width: 300px;
	height: 53px;
	top: 0;
	left: 0;
	background-color: #ffffff;
}

.user-text-color {
	color: #092f56;
	font-weight: bold;
}

.case-info {
	padding-left: 1070px;
	font-size: larger;
	font-weight: bold;
	color: #092f56;
}

.autoComplete-div {
	width: auto;
	padding-left: 8px;
}

.autoComplete {
	width: auto;
}

.autoComplete-list {
	color: #092f56;
	font-weight: 500;
}
